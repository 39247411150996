<template>
  <b-card>
    <div class="fama-table">
      <div v-if="dataLoading" class="spinner-border m-auto" role="status">
        <span class="sr-only">Loading...</span>
      </div>
      <div v-else-if="error.length !== 0" class="m-auto">
        {{ error }}
      </div>
      <div v-else-if="error.length === 0" style="width: 100%">
        <DxDataGrid
            v-if="can_list"
            id="material-table"
            ref="data-grid"
            width="100%"
            :show-borders="true"
            :data-source="dataRolePages"
            :allow-column-reordering="true"
            :allow-column-resizing="true"
            column-resizing-mode="widget"
            :column-auto-width="true"
            :show-column-lines="true"
            :show-row-lines="true"
            :row-alternation-enabled="true"
            :hover-state-enabled="true"
            key-expr="id"
            exporting="exportGrid"
            @row-inserted="onInserted"
            @row-updated="onUpdated"
            @row-removed="onRemoved"
            @exporting="onExporting"
        >
          <DxColumn data-field="id" caption="ID" :allow-editing="false"/>
          <DxColumn
              data-field="role_id"
              :name="this.$t('rolePages.role_id')"
              :caption="this.$t('rolePages.role_id')"
          >
            <DxLookup
                :data-source="dataRole"
                value-expr="id"
                display-expr="name"
            />
          </DxColumn>
          <DxColumn
              data-field="pages_id"
              :name="this.$t('rolePages.pages_id')"
              :caption="this.$t('rolePages.pages_id')"
          >
            <DxLookup
                :data-source="dataPages"
                value-expr="id"
                display-expr="name"
            />
          </DxColumn>
          <DxColumn
              data-field="can_list"
              data-type="boolean"
              :name="this.$t('rolePages.can_list')"
              :caption="this.$t('rolePages.can_list')"
          />
          <DxColumn
              data-field="can_create"
              data-type="boolean"
              :name="this.$t('rolePages.can_create')"
              :caption="this.$t('rolePages.can_create')"
          />
          <DxColumn
              data-field="can_edit"
              data-type="boolean"
              :name="this.$t('rolePages.can_edit')"
              :caption="this.$t('rolePages.can_edit')"
          />
          <DxColumn
              data-field="can_delete"
              data-type="boolean"
              :name="this.$t('rolePages.can_delete')"
              :caption="this.$t('rolePages.can_delete')"
          />
          <DxFilterRow :visible="true"/>
          <DxSearchPanel :visible="true"/>
          <DxColumnFixing :enabled="true"/>
          <DxColumnChooser :enabled="true"/>
          <DxScrolling mode="standard"/>
          <DxGrouping :context-menu-enabled="true"/>
          <DxGroupPanel :visible="true"/>
          <DxPaging :page-size="20"/>

          <DxPager
              :visible="true"
              :allowed-page-sizes="pageSizes"
              :display-mode="displayMode"
              :show-page-size-selector="showPageSizeSelector"
              :show-info="showInfo"
              :show-navigation-buttons="showNavButtons"
          />
          <DxExport
              :enabled="true"
              :visible="true"
              :allow-export-selected-data="false"
          />
          <DxEditing
              :allow-updating="can_edit"
              :allow-deleting="can_delete"
              :allow-adding="can_create"
              mode="row"
              :select-text-on-edit-start="true"
              :use-icons="true"
          />
        </DxDataGrid>
        <h1 v-else>{{ $t('noPermission') }}</h1>
      </div>
    </div>
    <b-modal
        ref="import-modal"
        centered
        title="Import"
        ok-only
        :ok-title="$t('buttons.ok')"
    >
      <b-card-text>{{ $t('general.importStarted') }}</b-card-text>
      <b-card-text v-if="importResult.length !== 0">
        {{ importResult }}
      </b-card-text>
      <div class="d-flex justify-content-center">
        <b-spinner v-if="importResult.length === 0"/>
      </div>
    </b-modal>
  </b-card>
</template>

<script>
import axios from '@axios';
import {BCard, BModal, BCardText, BSpinner} from 'bootstrap-vue';
import {
  DxDataGrid,
  DxPager,
  DxColumn,
  DxLookup,
  DxScrolling,
  DxColumnFixing,
  DxSearchPanel,
  DxColumnChooser,
  DxGroupPanel,
  DxGrouping,
  DxFilterRow,
  DxPaging,
  DxEditing,
  DxExport
} from 'devextreme-vue/data-grid';

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import {exportDataGrid} from 'devextreme/excel_exporter';
import {Workbook} from 'exceljs';
import saveAs from 'file-saver';
import 'jspdf-autotable';
import {readOnly} from "@/auth/utils";

export default {
  components: {
    BCard,
    BCardText,
    BModal,
    BSpinner,
    DxEditing,
    DxPager,
    DxFilterRow,
    DxColumn,
    DxColumnFixing,
    DxDataGrid,
    DxLookup,
    DxScrolling,
    DxSearchPanel,
    BCard,
    DxColumnChooser,
    DxGroupPanel,
    DxGrouping,
    DxPaging,
    DxExport
  },
  data() {
    return {
      can_edit: false,
      can_list: false,
      can_create: false,
      can_delete: false,
      pageSizes: [20, 50, 100, 200],
      showPageSizeSelector: true,
      showInfo: true,
      showNavButtons: true,
      enterKeyActions: ['startEdit', 'moveFocus'],
      enterKeyDirections: ['none', 'column', 'row'],
      enterKeyDirection: 'row',
      enterKeyAction: 'moveFocus',
      dataPages: [],
      dataRole: [],
      dataRolePages: [],
      languages: [],
      selectedLanguage: {},
      dataLoading: true,
      error: '',
      file: null,
      importResult: '',
      displayMode: 'full',
      readOnly: false
    };
  },
  async mounted() {
    this.readOnly = readOnly();
    await this.getPageAuths();
    await this.getLanguage();
    await this.getPages();
    await this.getRoles();
    await this.getRolePages();
  },
  methods: {
    async getPageAuths() {
      const pageAuths = await axios.post('/get-page-auth', {
        page: 'Roles : User Authorization'
      });
      this.can_edit = this.readOnly&&pageAuths.data.can_edit;
      this.can_list = pageAuths.data.can_list;
      this.can_delete = this.readOnly&&pageAuths.data.can_delete;
      this.can_create = this.readOnly&&pageAuths.data.can_create;
    },
    async getLanguage() {
      this.error = '';
      const response = await axios.get('/list-language');

      try {
        this.languages = response?.data?.result || [];
        const selected = this.languages.find(
            (x) => x?.code === localStorage.dbLanguage
        );
        this.selectedLanguage = selected;
      } catch (error) {
        this.error = e?.response?.data?.message || e?.message;
      }
    },
    async getPages() {
      this.error = '';
      const response = await axios.get('/list-pages');

      try {
        this.dataPages = response?.data?.result || [];
      } catch (error) {
        this.error = e?.response?.data?.message || e?.message;
      }
    },
    async getRoles() {
      this.error = '';
      const response = await axios.get('/list-role');

      try {
        this.dataRole = response?.data?.result || [];
      } catch (error) {
        this.error = e?.response?.data?.message || e?.message;
      }
    },
    async getRolePages() {
      this.dataLoading = true;
      this.error = '';
      const response = await axios.get('/list-role-pages');

      try {
        this.dataRolePages = response?.data?.result || [];
        this.dataLoading = false;
      } catch (error) {
        this.dataLoading = false;

        this.error = e?.response?.data?.message || e?.message;
      }
    },
    showToast({variant = 'success', title, text, icon = 'XIcon'}) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          text,
          icon,
          variant
        }
      });
    },
    onExporting(e) {
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet('Fama-RolePages');
      exportDataGrid({
        component: e.component,
        worksheet: worksheet,
        customizeCell: function (options) {
          const excelCell = options;
          excelCell.font = {name: 'Arial', size: 12};
          excelCell.alignment = {horizontal: 'left'};
        }
      }).then(function () {
        workbook.xlsx.writeBuffer().then(function (buffer) {
          saveAs(
              new Blob([buffer], {type: 'application/octet-stream'}),
              'Fama-RolePages.xlsx'
          );
        });
      });
      e.cancel = true;
    },
    closeAllMasterDetail() {
      this.$refs['data-grid'].instance.collapseAll(-1);
    },
    onLanguageSelect(lang) {
      this.selectedLanguage = lang;
      localStorage.dbLanguage = lang.code;
      this.refreshList();
    },
    onUpdated(e) {
      axios
          .put(`edit-role-pages/${e.data.id}`, e.data)
          .then((response) => {
            this.showToast({
              variant: 'success',
              text: 'Veri başarıyla güncellendi.'
            });
            this.refreshList();
          })
          .catch((err) => {
            this.showToast({
              variant: 'danger',
              text: err?.response?.data?.message || err?.message
            });
          });
    },
    onInserted(e) {
      axios
          .post('create-role-pages', e.data)
          .then((response) => {
            this.showToast({
              variant: 'success',
              text: 'Veri başarıyla eklendi.'
            });
            this.refreshList();
          })
          .catch((err) => {
            this.showToast({
              variant: 'danger',
              text: err?.response?.data?.message || err?.message
            });
          });
    },
    onRemoved(e) {
      axios
          .delete(`delete-role-pages/${e.data.id}`, {})
          .then((response) => {
            this.showToast({
              variant: 'success',
              text: 'Veri başarıyla silindi.'
            });
            this.refreshList();
          })
          .catch((err) => {
            this.showToast({
              variant: 'danger',
              text: err?.response?.data?.message || err?.message
            });
          });
    },
    async refreshList() {
      this.dataLoading = true;
      await this.getLanguage();
      await this.getPages();
      await this.getRoles();
      await this.getRolePages();
    }
  }
};
</script>

<style lang="scss">
@import './tables.scss';

.dx-master-detail-cell {
  background-color: bisque !important;
}
</style>
